<template>
  <section class='section'>
    <div class="bar-alert" v-if="!detail.account_confirm">
      <p class="text-alert">
        El producto no se podrá aprobar hasta que el usuario {{this.detail.username}} verifique su cuenta.
      </p>
    </div>
    <modal-send-message
      v-if="modalMenssage"
      :close="showModalMessage"
      :userName="detail.username"
      :messagesUser="messageUser"
      :action="sendMessageProduct"
      :validateMessage="detail.status"
    />
    <ModalChangeStatus
      v-if="modalChangeStatus"
      :close="showModalStatus"
      :action="aceptChangeStatus"
    />
    <modal-code
      v-if="isShowModalCode"
      :close="closeModalCode"
      :action="sendCodeProduct"
    />
    <modal-oferta
      v-if="isShowModalOffer"
      :detail="detail"
      :close="closeModalOffer"
      :action="sendOfertPrice"
    />
    <modal-notification
      v-if="isShowModalReceived"
      :close="closeModalReceived"
      :action="receivedShipping"
      :detail="detail"
    />
    <modal-notification
      v-if="isShowModalPayment"
      :close="closeModalPayment"
      :action="paymentNotification"
      :detail="detail"
    />
    <modal-delete
      v-if="isShowModalDelete"
      :close="closeModalDelete"
      :action="deleteProduct"
    />
    <modal-finalize
      v-if="isShowModalFinalize"
      :close="closeModalFinalize"
      :action="finalizeProduct"
    />
    <modalPayment
      v-if="isShowModalPaymentImage"
      :close="closeModalPaymentImage"
      :action="paymentImageNotification"
    />
    <ModalPaymentImage
      v-if="isShowImagePayment"
      :close="closeImagePayment"
      :propsImage="detail.image_payment"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item title-view">
          <h1>Detalle del producto <span v-if="detail.image_payment ? true : false" @click="showImagePayment" class="has-text-danger is-size-6" style="cursor: pointer;">Ver recibo de pago</span></h1>
          <a @click="returnView()">Regresar</a>
        </div>
      </div>
      <div class="level-right">
        <template v-if="detail.account_confirm">
        <div class="level-item">
          <button v-if="productStatusValue === 0" class="button is-success" @click="showModalCode">Preaprobar</button>
          <button v-if="productStatusValue === 1 || productStatusValue === 2 || productStatusValue === 3" :disabled="productStatusValue === 1 || productStatusValue === 2" class="button is-success"  @click="showModalReceived">{{ detail.place_deposit === '1' ? 'Pedir recibo de envío' : 'Producto recibido'}}</button>
          <button v-if="productStatusValue === 5 || productStatusValue === 6" class="button is-success" @click="showModalOffer">Envíar propuesta</button>
          <button v-if="productStatusValue === 7 || productStatusValue === 8" :disabled="productStatusValue === 7" class="button is-success" @click="showModalPayment">Reflejar pago en la App</button>
          <button v-if="productStatusValue === 9" class="button is-success" @click="showModalPaymentImage">Enviar recibo de pago</button>
          <button v-if="productStatusValue === 10" :disabled="productStatusValue === 11" @click="showModalFinalize" class="button is-success">Finalizar</button>
          <button v-if="productStatusValue === 11" class="button is-success" :disabled="loadingStatus || detail.status === 2" :class="{ 'is-loading': loadingStatus}" @click="aceptProduct(2)">Aprobar</button>
        </div>
        </template>
        <div class="level-item">
          <button class="button is-danger is-bordered" @click="showModalDelete('delete')">Eliminar</button>
        </div>
        <div class="level-item">
          <button class="button is-warning" :disabled="loadingStatus" :class="{ 'is-loading': loadingStatus}" @click="statusRejected()">Rechazar</button>
        </div>
      </div>
      </nav>
    <div class="columns is-multiline is-centered">
      <div class="column is-12">
        <div class="tabs is-boxed">
          <ul>
            <li :class="{'is-active': tabActive === 'datos'}" @click="tabActive = 'datos'"><a>Datos generales</a></li>
            <li v-if="detail?.ventas?.length > 0" :class="{'is-active': tabActive === 'venta'}" @click="tabActive = 'venta'"><a>Ventas</a></li>
            <li :class="{'is-active': tabActive === 'diagnostico'}" @click="productStatusValue >= 2 ? tabActive = 'diagnostico' : null"><a>Diagnóstico<TooltipStatus :data="productStatusValue" :pending="1" :complete="2"/></a></li>
            <li :class="{'is-active': tabActive === 'carta'}" @click="productStatusValue >= 8 ? tabActive = 'carta' : null"><a>Carta propiedad<TooltipStatus :data="productStatusValue" :pending="7" :complete="8"/></a></li>
            <li :class="{'is-active': tabActive === 'envio', 'is-hidden': detail.place_deposit !== '1'}" @click="productStatusValue >= 8 ? tabActive = 'envio' : null"><a>Datos de envío<TooltipStatus :data="productStatusValue" :pending="7" :complete="8"/></a></li>
          </ul>
        </div>
        <!-- Se comenta para validar si no tiene implicaciones --->
        <!-- <div class="box" v-if="!loadingProduct">
          <product-detail
            v-if="!isEdit && tabActive === 'datos'"
            :detail="detail"
            :editProduct="showEditProduct"
          />
          <ventas-detail
            v-else-if="!isEdit && tabActive === 'venta'"
            :detail="detail"
            :editProduct="showEditProduct"
          />
          <product-detail-edit
            v-else
            :detail="detail"
            :updateProduct="updateProduct"
            :loading="loadingProduct"
            :productId="parseInt(productId)"
            :cancelEdit="showEditProduct"
          />
        </div> -->
      <div class="columns is-multiline is-centered">
        <div class="column is-9">
          <div class="box" v-if="!loadingProduct">
            <product-detail
            v-if="!isEdit && tabActive === 'datos'"
            :detail="detail"
            :editProduct="showEditProduct"
            :action="updateProductDetails"
          />
          <ventas-detail
            v-else-if="!isEdit && tabActive === 'venta'"
            :detail="detail"
            :editProduct="showEditProduct"
          />
          <product-detail-edit
            v-else-if="isEdit && tabActive === 'edit'"
            :detail="detail"
            :updateProduct="updateProduct"
            :loading="loadingProduct"
            :productId="parseInt(productId)"
            :cancelEdit="showEditProduct"
          />
          <diagnostico-detail
            v-else-if="!isEdit && tabActive === 'diagnostico'"
            :detail="detail"
          />
          <carta-propiedad-detail
            v-else-if="!isEdit && tabActive === 'carta'"
            :detail="detail"
          />
          <datos-envio
            v-else-if="!isEdit && tabActive === 'envio'"
            :detail="detail"
          />
          </div>
          <div class="box is-loading has-text-centered" v-else>
            <loading-component />
          </div>
        </div>
        <div class="column is-3">
          <template v-if="detail.status !== isSold">
            <div class="box">
              <span v-if="visible" data-tooltip="Producto Visible">👁️</span>
              <span v-else data-tooltip="Producto No Visible" style="text-decoration:line-through;filter: gray;-webkit-filter: grayscale(1);filter: grayscale(1);">👁️</span>&nbsp;
              <input
                id="switchStatus"
                type="checkbox"
                name="switchStatus"
                class="switch is-rounded is-info"
                :checked="visible"
                @change="visibilityProduct()"
              >
              <label for="switchStatus">
                Producto visible
              </label>
            </div>
          </template>
          <div class="box">
            <span v-if="is_sold" data-tooltip="Producto Vendido">🛒</span>
            <span v-else data-tooltip="Producto No Vendido" style="text-decoration:line-through;filter: gray;-webkit-filter: grayscale(1);filter: grayscale(1);">🛒</span>&nbsp;
            <input
              id="switchStatusSold"
              type="checkbox"
              name="switchStatusSold"
              class="switch is-rounded is-info"
              :checked="is_sold"
              @change="isSoldProduct()"
            >
            <label for="switchStatusSold">
              Producto vendido
            </label>
          </div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="box" v-if="!loadingProfile">
                <product-user
                  :detail="detail"
                  :showModalMessage="showModalMessage"
                />
              </div>
              <div class="box is-loading has-text-centered" v-else>
                <loading-component />
              </div>
            </div>
            <div class="column is-12">
              <div class="box">
                <more-products
                  :similarProducts="detail.similarProducts"
                />
              </div>
            </div>
            <div class="column is-12">
              <div class="box">
                <comments-section :comments="detail.comments"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <Chat v-if="detail.userId !== 4" :userId="detail.userId" :nameUser="detail.name" :username="detail.username" :userImage="detail.userImage" :chatData="chatData"/>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import router from '@/router'
import gql from 'graphql-tag'

export default {
  name: 'DetalleDelProducto',
  components: {
    ProductDetail: () => import('@/components/Products/Detail'),
    CommentsSection: () => import('@/components/Products/CommentsSection'),
    VentasDetail: () => import('@/components/Products/Ventas'),
    ProductDetailEdit: () => import('@/components/Products/DetailEdit'),
    ProductUser: () => import('@/components/Products/User'),
    MoreProducts: () => import('@/components/Products/MoreProducts'),
    LoadingComponent: () => import('@/components/ui/Loading'),
    ModalSendMessage: () => import('@/components/Products/ModalSendMessage'),
    ModalChangeStatus: () => import('@/components/Products/ModalChangeStatus'),
    Chat: () => import('@/components/Chat'),
    ModalCode: () => import('@/components/Products/ModalCode'),
    DiagnosticoDetail: () => import('@/components/Products/Diagnostico'),
    ModalOferta: () => import('@/components/Products/ModalOferta'),
    CartaPropiedadDetail: () => import('@/components/Products/CartaPropiedad'),
    ModalNotification: () => import('@/components/Products/ModalNotification'),
    ModalPaymentImage: () => import('@/components/Products/ModalPaymentImage'),
    ModalPayment: () => import('@/components/Products/ModalPayment'),
    DatosEnvio: () => import('@/components/Products/DatosEnvio'),
    ModalDelete: () => import('@/components/Products/ModalDelete'),
    ModalFinalize: () => import('@/components/Products/ModalFinalize'),
    TooltipStatus: () => import('@/components/Products/TooltipStatus')
  },
  data () {
    return {
      saleDetail: {},
      productDetails: [],
      detail: {
        similarProducts: []
      },
      tabActive: 'datos',
      productId: this.$route.params.productId,
      loadingProduct: false,
      loadingProfile: false,
      isEdit: false,
      modalMenssage: false,
      modalChangeStatus: false,
      messageUser: [],
      loadingStatus: false,
      PENDING_STATUS: 1,
      visible: false,
      is_sold: false,
      isSold: 3,
      chatData: {},
      isShowModalCode: false,
      isShowModalOffer: false,
      isShowModalReceived: false,
      isShowModalPayment: false,
      isShowModalPaymentImage: false,
      isShowImagePayment: false,
      isShowModalDelete: false,
      isShowModalFinalize: false,
      statusUpdateIsLoading: false
    }
  },
  methods: {
    ...mapActions(['getProductDetail', 'editProduct', 'changeStatusProduct', 'sendMessage', 'deleteProductById', 'updateProductVisibility', 'getSaleProduct', 'getNotificationProduct', 'getChatId', 'updateProductPreapproved', 'updateProductProposal', 'changeStatusProductReceipt', 'changeStatusPaymentProduct', 'changeStatusProductPaymentImage', 'changeStatusFinalizeProduct', 'updateAditionalInformation', 'updateProductSold']),
    showEditProduct () {
      this.isEdit = !this.isEdit
      this.tabActive = !this.isEdit ? 'datos' : 'edit'
    },
    visibilityProduct () {
      this.updateProductVisibility(this.productId)
        .then(resp => {
          this.visible = !this.visible
        })
    },
    isSoldProduct () {
      this.updateProductSold(this.productId)
        .then(resp => {
          this.is_sold = !this.is_sold
        })
    },
    async updateProduct (product) {
      this.loadingProduct = true
      const success = await this.editProduct({ productId: this.productId, product })
      if (success) {
        this.getProductDetail({ productId: this.productId })
          .then((product) => {
            this.detail = product
            this.loadingProduct = false
            this.showEditProduct()
          })
      }
    },
    showModalMessage () {
      this.modalMenssage = !this.modalMenssage
    },
    showModalStatus () {
      this.modalChangeStatus = !this.modalChangeStatus
    },
    statusRejected () {
      this.modalChangeStatus = true
    },
    async aceptChangeStatus (data) {
      const newData = {
        status: 5,
        rejectedMessage: data
      }
      this.changeStatus(newData)

      window.location.reload()
    },
    aceptProduct (status) {
      const newData = {
        status: status,
        rejectedMessage: ''
      }
      this.changeStatus(newData)
    },
    changeStatus (status) {
      this.loadingStatus = true
      this.changeStatusProduct({ productId: this.productId, status })
        .then(success => {
          if (success) {
            this.modalChangeStatus = false
            this.loadingStatus = false
            this.detail.status = status.status
            this.getProductDetail({ productId: this.productId })
              .then((product) => {
                this.detail = product
                this.loadingProfile = false
              })
          }
        })
    },
    statusDeleted () {
      this.deleteProductById(parseInt(this.$route.params.productId, 10))
        .then(resp => {
          router.replace({ name: 'productos' })
        })
    },
    async sendMessageProduct (message) {
      await this.sendMessage({ productId: this.productId, body: message })
      this.getProductDetail({ productId: this.productId })
    },
    returnView () {
      this.$router.go(-1)
    },
    showModalCode () {
      this.isShowModalCode = true
    },
    closeModalCode () {
      this.getProductDetail({ productId: this.productId })

      this.isShowModalCode = false
    },
    async sendCodeProduct () {
      const response = await this.updateProductPreapproved({ productId: this.productId })

      this.getProductDetail({ productId: this.productId })
        .then((product) => {
          this.detail = product
          this.loadingProfile = false
        })

      return response
    },
    showModalOffer () {
      this.isShowModalOffer = true
    },
    closeModalOffer () {
      this.isShowModalOffer = false
    },
    async sendOfertPrice (totalPrice) {
      const response = await this.updateProductProposal({ productId: this.productId, amount: totalPrice })

      this.getProductDetail({ productId: this.productId })
        .then((product) => {
          this.detail = product
          this.loadingProfile = false
        })

      return response
    },
    showModalReceived () {
      this.isShowModalReceived = true
    },
    closeModalReceived () {
      this.isShowModalReceived = false
    },
    async receivedShipping () {
      const response = await this.changeStatusProductReceipt({ productId: this.productId })

      this.getProductDetail({ productId: this.productId })
        .then((product) => {
          this.detail = product
          this.loadingProfile = false
        })

      return response
    },
    showModalPayment () {
      this.isShowModalPayment = true
    },
    closeModalPayment () {
      this.isShowModalPayment = false
    },
    async paymentNotification () {
      const response = await this.changeStatusPaymentProduct({ productId: this.productId })

      this.getProductDetail({ productId: this.productId })
        .then((product) => {
          this.detail = product
          this.loadingProfile = false
        })

      return response
    },
    showModalPaymentImage () {
      this.isShowModalPaymentImage = true
    },
    closeModalPaymentImage () {
      this.isShowModalPaymentImage = false
    },
    async paymentImageNotification (file) {
      const response = await this.changeStatusProductPaymentImage({ productId: this.productId, fileImage: file })

      this.getProductDetail({ productId: this.productId })
        .then((product) => {
          this.detail = product
          this.loadingProfile = false
        })

      return response
    },
    showImagePayment () {
      this.isShowImagePayment = true
    },
    closeImagePayment () {
      this.isShowImagePayment = false
    },
    showModalDelete () {
      this.isShowModalDelete = true
    },
    closeModalDelete () {
      this.isShowModalDelete = false
    },
    async deleteProduct () {
      const response = this.statusDeleted()

      this.getProductDetail({ productId: this.productId })
        .then((product) => {
          this.detail = product
          this.loadingProfile = false
        })

      return response
    },
    showModalFinalize () {
      this.isShowModalFinalize = true
    },
    closeModalFinalize () {
      this.isShowModalFinalize = false
    },
    async finalizeProduct () {
      const response = await this.changeStatusFinalizeProduct({ productId: this.productId })

      this.getProductDetail({ productId: this.productId })
        .then((product) => {
          this.detail = product
          this.loadingProfile = false
        })

      return response
    },
    async updateProductDetails (data) {
      this.updateAditionalInformation({ productId: this.productId, body: data })
    },
    async sendAutomaticMessage (message) {
      let response = await this.$apollo.mutate({
        mutation: gql`
          mutation ($message: String, $userId: Int) {
            addMessageAdmin(userId: $userId, message: $message) {
              id
              message
              sentByMe
              createdAt
              chatId
              image
              userId
              component
              refered {
                title
                image
                itemId
              }
            }
          }
        `,
        variables: {
          message,
          userId: this.detail.userId
        }
      })

      response = response.data.addMessageAdmin
      this.$root.$emit('automatic-message-sent', response)
    }
  },
  watch: {
    tabActive (newVal) {
      if (newVal === 'edit') return
      this.isEdit = false
    },
    $route (to, from) {
      this.loadingProduct = true
      this.getProductDetail({ productId: to.params.productId })
        .then((product) => {
          this.detail = product
          this.loadingProduct = false
        })
    }
  },
  async beforeMount () {
    this.loadingProduct = true
    this.loadingProfile = true
    let product = await this.getProductDetail({ productId: this.productId })
    let message = await this.getNotificationProduct({ productId: this.productId })
    if (product.statusName === 'Vendido') {
      let sale = await this.getSaleProduct(this.productId)
      product = { ...product, saleDetail: sale }
    }
    this.messageUser = message
    this.detail = product
    this.visible = product.visible
    this.is_sold = product.is_sold
    this.loadingProduct = false
    this.loadingProfile = false

    const resp = await this.getChatId(parseInt(this.detail.userId))
    this.chatData = resp.payload
  },
  computed: {
    productStatusValue () {
      // closed_date = finalizado
      if (this.detail.closed_date !== null) return 11
      // image_payment_date = el administrador pago el precio
      if (this.detail.image_payment_date !== null) return 10
      // date_payment = el administrador aprobo el prepago
      if (this.detail.date_payment !== null) return 9
      // pdf_date = el usuario completo el pdf
      if (this.detail.pdf_date !== null) return 8
      // proposal_acepted_date = el usuario acepto la propuesta
      if (this.detail.proposal_acepted_date !== null) return 7
      // proposal_date = el admin propuso un precio
      if (this.detail.proposal_date !== null) return 6
      // image_receipt_date = el usuario mando la imagen del comprobante de dhl
      if (this.detail.image_receipt_date !== null) return 5
      // date_receipt = el admin aprueba el pdf y permite mandar la imagen del dhl
      if (this.detail.date_receipt !== null) return 4
      // show_market_price_date = el usuario vio el precio del market
      if (this.detail.show_market_price_date !== null) return 3
      // diagnostic_date = el usuario mando el diagnostico
      if (this.detail.diagnostic_date !== null) return 2
      // pre_approved_date = el admin preaprobo el producto
      if (this.detail.pre_approved_date !== null) return 1
      // producto nuevo
      return 0
    }
  }
}
</script>

<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .userImage {
    width: 100px;
    height: 100px;
    background-position: 0;
    border-radius: 50%;
  }
  .calificacion {
    font-size: 12px;
  }
  .bar-alert {
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.75);
    background: #f5e355;
  }
  .text-alert {
    text-align: center;
  }
  .tabs {
    margin-bottom: 0;
  }
  .tabs .is-active a{
    color: black;
  }
  .tabs.is-boxed ul li:not(.is-active) a {
    background-color: rgba(228, 228, 228, 1);
    color: grey;
  }
  .tabs.is-boxed ul li.is-active a {
    background-color: white;
  }
</style>
